@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Oswald', sans-serif;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    transform-style: preserve-3d;
    perspective: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.section-one {
    background-image: url("https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/bghome.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    z-index: -100;
}

.section-one>nav {
    display: flex;
    width: 100vw;
    padding: 1rem 0;
    justify-content: space-evenly;
}

.nav-item {
    display: grid;
    place-items: center;
    text-transform: uppercase;
    cursor: pointer;
}

.card {
    width: 80%;
    height: 100%;
    color: white !important;
    padding: 3rem 1rem;
    border-radius: 0px !important;
    text-align: center;
    background: rgba(0, 0, 0, 0.5) !important;
    border: 2px solid white !important;
}

.ecobonus {
    font-family: 'Oswald', sans-serif;
    font-size: 4vw;
}

.showroom {
    font-family: 'Oswald', sans-serif;
    font-size: 3vw;
}

.section-two {
    padding: 3rem 0;
    height: 100%;
    background-color: #333;
}

.section-two-header {
    color: rgb(149, 123, 95);
    text-transform: uppercase;
    font-size: 6rem;
}

.title-item {
    color: rgb(149, 123, 95);
    text-transform: uppercase;
    font-size: 2.5rem;
}

.subtitle-item {
    color: white;
    font-size: larger !important;
}

.section-three {
    padding: 4rem 1rem 10rem 1rem;
    height: 100%;
    background-image: url("https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/bgthree.svg");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

.section-three-title {
    font-size: 3rem;
    margin: 4rem 0 4rem 4rem;

}

.section-three-img {
    border: 2px solid black;
    width: 500px;
    height: 375px;
    box-shadow: -5px -5px 30px;
}

.section-three-img-two {
    margin-top: 6rem;
    border: 2px solid black;
    width: 500px;
    height: 375px;
    box-shadow: 5px 5px 30px;
}

.hr {
    background-color: black;
    color: black;
    margin-top: 10rem;
    margin-bottom: 8rem;
    border-width: 0;
    height: 2px;
    width: 90%;
}

.description {
    font-size: 2rem;
}

.section-three-bottom-img {
    margin-top: 10rem;
    width: 281px;
    height: 410px;
    border: 2px solid black;
    box-shadow: 5px 5px 30px;
}

.section-three-bottom-img-two {
    margin-top: 5rem;
    width: 295px;
    height: 398px;
    border: 2px solid black;
    box-shadow: 5px 5px 30px;
}

.section-three-bottom-img-three {
    margin-top: 10rem;
    width: 281px;
    height: 410px;
    border: 2px solid black;
    box-shadow: 5px 5px 30px;
}

.aiuti-stato-container {
    width: 60%;
    text-align: center;
    margin: 3rem auto;
    color: white;
}

.footer-container {
    padding: 0rem 4rem;
    height: 100%;
    background-color: rgb(158, 158, 158);
    color: rgb(0, 0, 0)
}

.footer {
    padding: 2rem 0
}

.footer-img {
    width: 120px;
    height: 100px;
    margin-left: 10px;
    margin-top: 5px;
}

.footer-grid-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contacts {
    font-family: 'Oswald', sans-serif;
    font-weight: bolder;
    font-size: 20px;
}

.link {
    text-decoration: none;
    color: white;
}

.link>h2 {
    cursor: pointer !important;
}

.splide__arrow {
    height: 5em !important;
    width: 5em !important;
}

.splide__arrow svg {
    height: 4em !important;
    width: 4em !important;
}

.product-img {
    height: 380px;
}

.product-card {
    text-align: center;
    cursor: pointer;
    height: 500px;
    max-height: 500px;
    max-width: 500px;
}

.hvr-underline-from-center {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}

.hvr-underline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #000000;
    height: 2px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
    left: 0;
    right: 0;
}

.hvr-underline-from-center-white {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}

.hvr-underline-from-center-white:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #ffffff;
    height: 2px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-underline-from-center-white:hover:before,
.hvr-underline-from-center-white:focus:before,
.hvr-underline-from-center-white:active:before {
    left: 0;
    right: 0;
}

.product-container {
    padding: 1rem 3rem 10rem 3rem;
    background-image: url('https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/bgtrans.png');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.nav-container {
    display: flex;
    justify-content: space-evenly;
}

.product-title {
    text-transform: uppercase;
    color: rgb(149, 123, 95);
    font-size: 3rem;
    margin: 0;
}

.product-title-hr {
    width: 23%;
    height: 2px;
    color: rgb(149, 123, 95);
    background-color: rgb(149, 123, 95);
    border-width: 0;
}

.product-img-item {
    width: 200px;
    height: 200px;
    transition: all 0.5s;
}

.product-img-item-vertical {
    width: 200px;
    height: 400px;
    transition: all 0.5s;
}

.product-img-item-pergosky {
    width: 300px;
    height: 200px;
    transition: all 0.5s;
}

.product-img-item-mobile {
    width: 150px;
    height: 150px;
}

.pavimenti-nav-desktop {
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 10;
    padding: 1rem 3rem;
}

.product-img-item:hover {
    transform: scale(1.1);
}

.dialog-item-title {
    font-family: 'Oswald', sans-serif !important;
    text-transform: uppercase;
    margin: 0;
}

.item-subtitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 3rem;
    font-size: x-large;
    margin-top: 2rem;
}

.item-subtitle>* {
    margin: 0;
}

.item-subtitle-mobile {
    text-align: center;
    font-size: x-large;
    margin-top: 2rem;
}

.item-subtitle-mobile>* {
    margin: 0;
}

.close-dialog-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pedane-home {
    padding-top: 6rem;
    background-image: url("https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pedane/pavimento.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.pedane-bottom {
    background-image: url('https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/bgtrans.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 4rem
}

.agevolazioni-container {
    margin-top: 1rem;
    padding: 0 2rem 4rem 2rem;
    font-size: 1.4rem;
}

.agevolazioni-subtitle {
    color: red;
    text-transform: uppercase;
    font-size: 2rem;
}

.agevolazioni-sub-subtitle {
    text-transform: uppercase;
    font-size: 1.5rem;
    margin: 0
}

.agevolazioni-title {
    font-size: 1.5rem;
    text-transform: uppercase;
}

.span-agevolazioni {
    color: red;
    font-weight: bolder;
}

.contact-container {
    width: 100%;
    height: 100vh;
    text-align: center;
    background-image: url('https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/bgtrans.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.contact-container>h1 {
    font-size: 3rem;
    text-transform: uppercase;
    color: rgb(149, 123, 95);
}

.info-container {
    background-color: white;
    border-radius: 10px;
    border: 2px solid black;
    padding: 2rem;
    width: 50%;
    margin: 0 auto;
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.75);
}

.installazioni-container {
    padding: 1rem 3rem;
}

.installazioni-container-mobile {
    padding-bottom: 4rem;
}


.installazioni-subcontainer {
    width: 100%;
    text-align: center;
    background-image: url('https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/bgtrans.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 2rem 0
}


.lavori-img {
    border-radius: 10px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.75);
}

.lavori-img-dialog {
    width: 400px;
    height: 400px;
    border-radius: 10px;
}

.lavori-img-dialog-mobile {
    width: 250px;
    height: 250px;
    border-radius: 10px;
}

/********************************************/

.card-container-mobile {
    width: 100%;
    margin-top: 1rem;
    padding-bottom: 4rem;
    position: absolute;
    top: 20%;
    z-index: 50;
}

.ecobonus-mobile {
    font-family: 'Oswald', sans-serif;
    font-size: 5vh;
}

.showroom-mobile {
    font-size: 4vh;
}

.hr-mobile {
    background-color: black;
    color: black;
    margin-top: 4rem;
    border-width: 0;
    height: 1px;
    width: 60%;
}

.menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    width: 50px;
    height: 50px;
}

.line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}

.line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}

.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

.drawer-items {
    margin: 0;
    text-transform: uppercase;
    color: rgb(149, 123, 95);
}

.drawer-box-item {
    border-bottom: 1px solid rgb(149, 123, 95);
    width: 50%;
    padding-bottom: 1rem;
}

.section-three-mobile {
    height: 100%;
    background-image: url("https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/bgthree.svg");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1rem;
    margin-top: -0.5rem;
    padding-bottom: 5rem;
}

.section-three-img-mobile {
    border: 2px solid black;
    width: 300px;
    height: 225px;
    box-shadow: -5px -5px 30px;
}

.section-three-title-mobile {
    font-size: 2.5rem;
}

.description-mobile {
    font-size: 1.6rem;
}

.section-two-mobile {
    height: 100%;
    padding-top: 4rem;
    background-color: #333;
}

.section-two-header-mobile {
    color: rgb(149, 123, 95);
    text-transform: uppercase;
    font-size: 3rem;
}

.title-item-mobile {
    color: rgb(149, 123, 95);
    text-transform: uppercase;
    font-size: 2rem;
}

.nav-mobile {
    padding: 1rem;
    display: flex;
    position: absolute;
    top: 0;
    width: 90%;
}

.nav-mobile-container {
    width: 100vw;
    background-color: #333;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 10rem;
}

.carousel-splide-img {
    height: 465px;
    width: 100%;
    border: 2px solid black;
}

.product-container-mobile {
    background-image: url('https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/bgtrans.png');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    padding-bottom: 4rem;
}

.product-title-hr-mobile {
    width: 65%;
    height: 2px;
    color: rgb(149, 123, 95);
    background-color: rgb(149, 123, 95);
    border-width: 0;
}

.product-title-mobile {
    text-transform: uppercase;
    color: rgb(149, 123, 95);
    font-size: 2.5rem;
    margin: 0;
}

.pavimenti-container-mobile {
    padding: 2rem 1rem;
    background-color: rgb(159, 159, 159, 0.5);
    border-radius: 20px;
    border: 1px solid white;
    text-align: center;
    margin: 1rem 2rem;
}

.pavimenti-container {
    background-color: rgb(159, 159, 159, 0.5);
    border-radius: 20px;
    border: 1px solid white;
    padding: 1rem 3rem
}

.pavimenti-splide-mobile {
    height: 300px;
    width: 300px;
    border: 2px solid black;
    border-radius: 10px;
}

.pavimenti-splide {
    height: 400px;
    width: 400px;
    border: 2px solid black;
}

.pavimenti-paragraph {
    font-size: 1.5rem !important;
    font-weight: bolder !important;
}

.footer-mobile {
    padding: 0 0.3rem
}

.footer-container-mobile {
    padding: 1.5rem 0.5rem;
    height: 100%;
    background-color: rgb(158, 158, 158);
    color: rgb(0, 0, 0)
}

.contacts-mobile {
    font-family: 'Oswald', sans-serif;
    font-weight: bolder;
    font-size: 0.4rem;
}